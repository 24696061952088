<template>
  <div class="admin-dashboard">
    <h1>Dashboard</h1>
  </div>
</template>

<script>
import { adminPageTitle } from "../../utils/utils";
export default {
  name: "Dashboard",
  metaInfo: {
    title: adminPageTitle("Dashboard"),
  },
};
</script>
